@import '../../variables';

#emails {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  header.viewHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
                    160deg,
                    rgba(26, 115, 232, 1) 30%,
                    rgba(120, 178, 255, 1) 100%
    );
    padding: 20px;
    min-height: 250px;

    article {
      color: white;
      min-width: 600px;

      h3 {
        font-size: 30px;
        letter-spacing: 0.5px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        letter-spacing: 0.5px;
        font-weight: 400;
      }
    }
  }

  section.body {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .sectionDescription {
    display: block;
    font-size: 13px;
    color: #666;
    margin-bottom: 10px;
  }

  .formFooter {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {
      height: 36px;
      background-color: $main-color;
      border-radius: 4px;
      padding: 0 15px;
      color: white;
      outline: none;
      border: none;
      font-size: 13px;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background-color: #eee;
        color: #999;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
