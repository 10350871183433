@import '../../variables';

.container {
    padding: 15px 10px;
    border: 2px dashed $main-color;
    display: flex;
    background-color: #e7eaf5;
    border-radius: 4px;
    align-items: center;
    max-width: 320px;
    position: relative;

    .noFile,
    .setFile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
    }

    .setFile p {
        font-size: 12px;
        width: 180px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;

        &:hover {
            cursor: pointer;
        }
    }

    article {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 12px;
            margin-bottom: 5px;
            color: $font-color;
        }

        p {
            font-size: 10px;
            color: $font-color;
        }
    }

    button {
        background-color: $main-color;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        border-radius: 4px;
        padding: 0 15px;
        height: 36px;
        font-size: 13px;

        &:hover {
            cursor: pointer;
        }
    }

    input {
        display: none;
    }
}
