.form {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  color: #384762;
  min-width: 600px;

  .step {
    display: flex;
    flex-direction: column;

    .header {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      gap: 15px;

      .counter {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #1a73e8;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
      }

      h3 {
        font-size: 16px;
        letter-spacing: 0.5px;
      }
    }
  }

  .content {
    margin-left: 32px;
    padding: 0 25px;
    border-left: 1px solid #ddd;
  }

  footer {
    border-top: 1px solid #ddd;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
}
