$font-color: #414552;
$main-color: #1a73e8;
$disabled-color: #969ea6;
$error-dark-color: #dc3030;
$error-light-color: #ffc8ca;
$light-border-color: #d8dee7;
$soft-green: #5ccdad;

$box-shadow-with-border: 0 0 0 1px rgba(-48, 49, 61, 0.1),
  0 2px 5px 0 rgba(48, 49, 61, 0.1), 0 1px 1.5px 0 #00000012,
  0 1px 2px 0 #00000014, 0 0 0 0 transparent;
$box-shadow-with-border-hover: 0 0 0 1px rgba(-48, 49, 61, 0.1),
  0 2px 5px 0 rgba(48, 49, 61, 0.1), 0 1px 1.5px 0 #00000012,
  0 1px 4px 2px #00000014, 0 0 0 0 transparent;
